import {useEffect,useState} from 'react'

export default function Cookies(props){
    const [open,setOpen]=useState(true);
    const [cookiesConsent,setCookiesConsent] = useState(null);

    const handleCookies = () =>{
        setOpen(false);
        setCookiesConsent(true);
        localStorage.setItem("cookiesConsent", true);

    }
    useEffect(() => {
        setCookiesConsent(localStorage.getItem("cookiesConsent"));
    }, [])
    return(
        <>
        {!cookiesConsent ? 
            <div className={`py-10 px-20 z-1000 bg-dark-blue text-primary-white fixed bottom-0 left-0 right-0 text-center font-bold text-12 ${open ? '' : 'hidden'}`}>
                <p className="inline-block">
                     En poursuivant votre navigation, vous acceptez l’utilisation de cookies pour vous offrir une meilleure expérience utilisateur
                </p>
                <button className="inline-block ml-10 hover:bg-primary-white py-1 px-5 rounded font-bold border-primary-white border-2 hover:text-dark-blue transition-all duration-200" aria-label="Accepter les cookies" onClick={()=>handleCookies()}>
                    ACCEPTER
                </button>
                
            </div>
        : 
            null
        }

        </>
    )
}