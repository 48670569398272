var CryptoJS = require("crypto-js");

export function get(zip) {
  var url =process.env.NEXT_PUBLIC_WEATHER_API_URL;
  var key = process.env.NEXT_PUBLIC_WEATHER_API_KEY;
  var zipcode = zip ?? "10000";

  /*var app_id = "TeaG6U6s";
  var consumer_key =
    "dj0yJmk9NlUxVkdhNDRtM2ZLJmQ9WVdrOVZHVmhSelpWTm5NbWNHbzlNQS0tJnM9Y29uc3VtZXJzZWNyZXQmc3Y9MCZ4PTNi";
  var consumer_secret = "f132cee40a887984b882b2b92461fe6bf73c72ea";
  var concat = "&";
  var oauth = {
    oauth_consumer_key: consumer_key,
    oauth_nonce: Math.random().toString(36).substring(2),
    oauth_signature_method: "HMAC-SHA1",
    oauth_timestamp: parseInt(new Date().getTime() / 1000).toString(),
    oauth_version: "1.0",
  };

  var merged = {
    ...query,
    ...oauth,
  };

  // Note the sorting here is required
  var merged_arr = Object.keys(merged)
    .sort()
    .map((k) => {
      return [k + "=" + encodeURIComponent(merged[k])];
    });

  var signature_base_str =
    method +
    concat +
    encodeURIComponent(url) +
    concat +
    encodeURIComponent(merged_arr.join(concat));

  var composite_key = encodeURIComponent(consumer_secret) + concat;
  var hash = CryptoJS.HmacSHA1(signature_base_str, composite_key);
  var signature = hash.toString(CryptoJS.enc.Base64);

  oauth["oauth_signature"] = signature;

  var auth_header =
    "OAuth " +
    Object.keys(oauth)
      .map((k) => {
        return [k + '="' + oauth[k] + '"'];
      })
      .join(",");*/

  return new Promise((resolve, reject) => {
    fetch(`${url}zip=${zipcode},fr&appid=${key}&lang=fr&lang=fr&units=metric`, {
      method: "GET",
      /*headers: {
        Authorization: auth_header,
        "X-Yahoo-App-Id": app_id,
      },*/
    })
      .then((response) => response.json())
      .then((data) => resolve(data))
      .catch((err) => reject(err));
  });
}

export function getCodeImage(code) {
  switch (code) {
    case 0:
      return "tornado.png";
    case 1:
      return "tropicalStorm.png";
    case 2:
      return "hurricane.png";
    case 3:
      return "severeThunderstorms.png";
    case 4:
      return "thunderstorms.png";
    case 5:
      return "mixedRainAndSnow.png";
    case 6:
      return "mixedRainAndSleet.png";
    case 7:
      return "mixedRainAndSleet.png";
    case 8:
      return "freezingDrizzle.png";
    case 9:
      return "drizzle.png";
    case 10:
      return "freezingRain.png";
    case 11:
      return "showers.png";
    case 12:
      return "rain.png";
    case 13:
      return "snowFlurries.png";
    case 14:
      return "lightSnowShowers.png";
    case 15:
      return "blowingSnow.png";
    case 16:
      return "snow.png";
    case 17:
      return "hail.png";
    case 18:
      return "sleet.png";
    case 19:
      return "dust.png";
    case 20:
      return "foggy.png";
    case 21:
      return "haze.png";
    case 22:
      return "smoky.png";
    case 23:
      return "blustery.png";
    case 24:
      return "windy.png";
    case 25:
      return "cold.png";
    case 26:
      return "cloudy.png";
    case 27:
      return "mostlyCloudy.png";
    case 28:
      return "mostlyCloudy.png";
    case 29:
      return "mostlySunny.png";
    case 30:
      return "mostlySunny.png";
    case 31:
      return "clear.png";
    case 32:
      return "sunny.png";
    case 33:
      return "fair.png";
    case 34:
      return "fair.png";
    case 35:
      return "mixedRainAndHail.png";
    case 36:
      return "hot.png";
    case 37:
      return "isolatedThunderstorms.png";
    case 38:
      return "scatteredThundershowers.png";
    case 39:
      return "scatteredShowers.png";
    case 40:
      return "scatteredShowers.png";
    case 41:
      return "scatteredSnowShowers.png";
    case 42:
      return "heavySnow.png";
    case 43:
      return "snow.png";
    case 44:
      return "dunno.png";
    case 45:
      return "scatteredShowers.png";
    case 46:
      return "scatteredSnowShowers.png";
    case 47:
      return "scatteredThundershowers.png";
  }
}
