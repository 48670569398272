import {useEffect,useState} from 'react'
import {DialogActions,Dialog,DialogContent,Button} from '@material-ui/core';
import IcomoonReact from "icomoon-react";
import iconSet from "../../public/selection.json";

export default function ConfirmationDialog(props){
    const [dialogContent,setDialogContent]=useState({});
    const setUpDialog= (error,type) => {
        let dialogContent = {
            title       : '',
            text        : '',
            image       : null,
            buttonText  : 'Retour',
        }
        if(error){
            dialogContent['title']='Il ya eu une erreur.';
            dialogContent['text']='Merci de réessayer ultérieurement';
        } else{
            switch(type){
                case 'newsletter':
                    dialogContent['title']='Votre inscription à notre newsletter a bien été prise en compte';
                    dialogContent['text']='Vous recevrez désormais des emails pour vous informer des dernières actualités';
                    dialogContent['image']='ico-illu-info.png';
                    break;
                    
                case 'info':
                    dialogContent['title']='Merci ! Votre info a bien été envoyée';
                    dialogContent['text']='Retrouvez votre info et toutes les autres envoyées par un utilisateur dans la rubrique « Les Aubois vous informent »';
                    dialogContent['image']='ico-illu-info.png';
                    break;
                    
                case 'question':
                    dialogContent['title']='Merci ! Votre question a bien été envoyée';
                    dialogContent['text']='Nous vous répondrons au plus vite.   ';
                    dialogContent['image']='ico-illu-question.png';
                    break;

                case 'content':
                    dialogContent['title']='Merci ! Votre signalement a bien été envoyé';
                    dialogContent['text']='Nous vous répondrons au plus vite.   ';
                    dialogContent['image']='ico-illu-question.png';
                    break;
                
                default:
                    break;
            }            
        }

        return dialogContent;
    }
    useEffect(() => {
        let content = setUpDialog(props.hasError,props.type)
        setDialogContent(content);
    },[props])

    return(
        <Dialog
            open={props.open}
            onClose={props.onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            id={`alert-dialog-${props.type}`}
            className="validation-dialog"
            
        >
        <DialogContent>
            {props.hasError ?
                <div className="text-center text-primary-red mb-20">
                    <IcomoonReact iconSet={iconSet} icon="notification" size={80} color="#F15152"/>

                </div>
            :
                <img src={`/images/validation/${dialogContent['image']}`} className="mx-auto w-180 mb-20" alt="Succès"/>
            }
            <p className={`text-center font-bold ${props.hasError ? 'text-primary-red':''}`}>{dialogContent['title']}</p>
            <p className={`text-center mt-20 mb-20 ${props.hasError ? 'text-primary-red':''}`}>{dialogContent['text']}</p>
            

          

        </DialogContent>
        <DialogActions>
          <button className="app-button-outlined" onClick={props.onClose}>{dialogContent['buttonText']}</button>
        </DialogActions>
      </Dialog>
    )
}
