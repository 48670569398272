import {useEffect, useState,useContext} from "react"

import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import Footer from "../Footer"
import Head from 'next/head'
import Header from "../Header"
import Link from 'next/link'
import Tailwind from "../../tailwind.config"
import styles from './index.module.css'
import Cookies from '../Dialog/Cookies';
import { Context } from "../../utils/store";

import {useRouter} from 'next/router'


export const siteTitle = "Canal 32"
export default function Layout({ children, home, thematiques, breakingNews, cities }) {
    const [showGoToTopButton, setShowGoToTopButton] = useState(false);
    
    useEffect(() => {
        window.addEventListener("scroll", scroll);
        function scroll() {
            if (window.pageYOffset > window.innerHeight) {
                setShowGoToTopButton(true)
            } else {
                setShowGoToTopButton(false)
            }
        }   
        scroll();
        
        return () => window.removeEventListener("scroll", scroll)
        
        }, []);
    return (
        <>
            <Head>
                <link rel="icon" href="/images/logo-canal32.jpeg" />
                <meta
                    name="description"
                    content="Canal 32 - Site web"
                />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Head>
            <Header breakingNews={breakingNews} thematiques={thematiques} cities={cities} />
            <div className="app-container pt-210 phone:pt-202 ">
                {children}
            </div>
            
            {
                showGoToTopButton &&
                <button
                className={`bg-primary-blue px-6 animate-bounce flex items-center justify-center`}
                style={{
                    color: "white",
                    position: "fixed",
                    bottom: 16,
                    right: 16,
                    height:40,
                    width: 40,
                    borderRadius: 20,
                    zIndex: 9999
                }} 
                onClick={() => {
                    window.scrollTo(0, 0)
                }}
                >
                <ArrowUpwardIcon
                fontSize="large" />
                </button>
            }
    
            <Footer thematiques={thematiques} />
            <Cookies/>
            
        </>
    )
}