import moment from "moment";

/* 
    @Params
    
    - template_variables : the list of vars to extract
    - type : the type received (article, etc...)
*/

export default function extractVars(template_variables, type) {
  switch (type) {
    case "article": {
      let video_date_timestamp = "",
        video_megastar = "",
        infos_is_new = "",
        video_compteur_vue_total = "",
        video_thumbs = "",
        video_auteur = "",
        video_tags = "",
        video_date = "",
        video_compteur_vue = "",
        video_thematique = "",
        video_fichier = "",
        video_date_mel = "",
        video_duree_de_vie = "",
        video_fichier_ok = "",
        video_longitude = "",
        video_latitude = "",
        video_archivee = "",
        video_emission = "";

      template_variables.map((e) => {
        switch (e.name) {
          case "video_date_timestamp": {
            video_date_timestamp = moment.unix(e.value).format("DD-MM-YY");
            break;
          }
          case "video_megastar": {
            video_megastar = e.value;
            break;
          }
          case "infos_is_new": {
            infos_is_new = e.value;
            break;
          }
          case "video_compteur_vue_total": {
            video_compteur_vue_total = e.value;
            break;
          }
          case "video_thumbs": {
            video_thumbs = e.value;
            break;
          }
          case "video_auteur": {
            video_auteur = e.value;
            break;
          }
          case "video_tags": {
            video_tags = e.value;
            break;
          }
          case "video_date": {
            video_date = e.value;
            break;
          }
          case "video_compteur_vue": {
            video_compteur_vue = e.value;
            break;
          }
          case "video_thematique": {
            video_thematique = e.value;
            break;
          }
          case "video_fichier": {
            video_fichier = e.value;
            break;
          }
          case "video_date_mel": {
            video_date_mel = moment.unix(e.value).format("DD-MM-YYYY");
            break;
          }
          case "video_duree_de_vie": {
            video_duree_de_vie = e.value;
            break;
          }
          case "video_fichier_ok": {
            video_fichier_ok = e.value;
            break;
          }
          case "video_longitude": {
            video_longitude = e.value;
            break;
          }
          case "video_latitude" : {
            video_latitude = e.value;
            break;
          }
          case "video_archivee" : {
            video_archivee = e.value;
            break;
          }
          case "video_emission": {
            video_emission = e.value;
            break;
          }
        }
      });

      return {
        video_date_timestamp: video_date_timestamp,
        video_megastar: video_megastar,
        infos_is_new: infos_is_new,
        video_compteur_vue_total: video_compteur_vue_total,
        video_thumbs: video_thumbs,
        video_auteur: video_auteur,
        video_tags: video_tags,
        video_date: video_date,
        video_compteur_vue: video_compteur_vue,
        video_thematique: video_thematique,
        video_fichier: video_fichier,
        video_date_mel: video_date_mel,
        video_duree_de_vie: video_duree_de_vie,
        video_fichier_ok: video_fichier_ok,
        video_longitude: video_longitude,
        video_latitude: video_latitude,
        video_emission: video_emission,
        video_archivee: video_archivee,
      };
    }
    case "emission": {
      let thematiqueCode = "",
        banniereEmissions = "",
        titre_appli = "";

      template_variables.map((e) => {
        switch (e.name) {
          case "thematique_code": {
            thematiqueCode = e.value;
            break;
          }
          case "banniere-emissions": {
            banniereEmissions = e.value;
            break;
          }
          case "titre_appli": {
            titre_appli = e.value;
            break;
          }
        }
      });
      return {
        thematiqueCode: thematiqueCode,
        banniereEmissions: banniereEmissions,
        titre_appli: titre_appli,
      };
    }
    case "aubois": {
      let actus_aubois_nom = "",
      actus_aubois_fichier_img = "",
      video_date_timestamp = "";
      template_variables.map((e) => {
        switch (e.name) {
            case "video_date_timestamp" : {
              video_date_timestamp = moment.unix(e.value).format("DD-MM-YYYY");
                break;
            }
            case "actus_aubois_nom" : {
              actus_aubois_nom = e.value
              break;
            }
            case "actus_aubois_fichier_img" : {
              actus_aubois_fichier_img = e.value
            }
        }
      });
      return {
        actus_aubois_nom: actus_aubois_nom,
        video_date_timestamp: video_date_timestamp,
        actus_aubois_fichier_img: actus_aubois_fichier_img
      }
    }

    case 'team' :
      return {
        email_equipe : template_variables.find(e => e.name === 'email-equipe')?.value,
        image_contact : template_variables.find(e => e.name === 'image-contact')?.value,
        role : template_variables.find(e => e.name === 'fonction')?.value,
      }
  }
  return {};
}
