import Head from 'next/head';
import Network from '../../constants/Network';

export default function CustomHead(props){
    const { article,isArticle } = props;
    const origin = process.env.ORIGIN;

    const getImage = (isShow) =>{
        let image;
        let host = Network.BASE_URL;
        if(isShow){
            image = article.template_variables.find(el=>el.name == 'banniere-emissions')?.value;
        } else {
            image = article.template_variables.find(el=>el.name == 'video_thumbs')?.value;
        }

        image = host + image;

        return image;
    }

    let infos= {}; 

    let title = props.title ? props.title : article ? article.pagetitle :'';
    title = title.length>0 && title!='undefined' ? title + ' |' : '';

    let image = article ? getImage(props.isShow) : origin + '/images/logo-canal32.jpeg';

    let description = props.description ? props.description : "L'actualité dans l'Aube 24h/24";
    infos['image']          = image;
    infos['title']          = title;
    infos['description']    = description;
    

    return(
        <Head>
            <title>{infos.title} Canal 32</title>
            <meta name="description" content={infos.description}/>

            {/* Twitter */}
            <meta name="twitter:card" content="summary" />
            <meta name="twitter:site" content="@canal32" />
            <meta name="twitter:title" content={`${infos.title} Canal 32`} />
            <meta name="twitter:description" content={infos.description}/>
            <meta name="twitter:image" content={infos.image} />

            {/* Facebook */}
            {isArticle ? 
                <meta property="og:url" content={`${process.env.ORIGIN}/${article.uri}`}/>
            :
                null
            }


            {/* Custom Canal32 script */}
            <script src="/spfct_v3_canal32.js" />
            
            <meta property="og:title" content={`${infos.title} Canal 32`}/>
            <meta property="og:image" content={`${infos.image}?v=1`} />
            <meta property="og:description" content={infos.description}/>
            <meta property="og:image:width" content="786" />
            <meta property="og:image:height" content="425" />
        </Head>
    )
}
