import {useEffect,useState} from 'react'
import {DialogActions,Dialog,DialogContent,Button} from '@material-ui/core';
import IcomoonReact from "icomoon-react";
import iconSet from "../../public/selection.json";

export default function LocationDialog(props){



    return(
        <Dialog
            open={props.open}
            /*onClose={props.onClose}*/
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            id={`alert-dialog-geolocation`}
            className="validation-dialog"
            
        >
        <DialogContent>
            <div className="text-center text-primary-red mb-20">
                <IcomoonReact iconSet={iconSet} icon="ico-around" size={80} color="#023B6D"/>

            </div>
            
            <p className={`text-center font-bold`}>Canal 32 souhaite connaître votre position</p>
            <p className={`text-center mt-20 mb-20`}>Nous utilisons ces données afin d'améliorer votre expérience utilisateur </p>
            

          

        </DialogContent>
        <DialogActions>
          <button className="app-button-outlined app-button-outlined--danger" onClick={()=>props.handleResponse(false)}>Refuser</button>
          <button className="app-button-primary" onClick={()=>props.handleResponse(true)}>Accepter</button>
        </DialogActions>
      </Dialog>
    )
}