import BasicNews from "./BasicNews";
import BreakingNews from "./BreakingNews";
import MainPart from "./MainBanner";
import NavBar from "./NavBar";
import { useState } from "react";


export default function Header({ thematiques, breakingNews, cities }) {
  const [open,setOpen] = useState(false);
  const handleOpen = ()=>{
    if(!open){
      document.body.style.overflow = 'hidden';
    }
    else{
      document.body.style.overflow = 'auto';

    }
    setOpen(!open);

  }
  return (
    <>
    
      <header className="fixed z-50 top-0 left-0 right-0 w-full h-210 phone:h-202">
        <BasicNews />
        <BreakingNews data={breakingNews} />
        <MainPart openMenu={open} handleOpen={handleOpen}/>
        <NavBar thematiques={thematiques} cities={cities} openMenu={open} handleOpen={handleOpen}/>
      </header>    
    </>

  );
}
