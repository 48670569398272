import FacebookIcon from '@material-ui/icons/Facebook';
import Icon from "../Icon";
import RssFeedIcon from '@material-ui/icons/RssFeed';
import TwitterIcon from '@material-ui/icons/Twitter';

export default function SocialMedia({classNameContainer}) {
    return (
        <div className={`${classNameContainer}`}>
            <   Icon icon="icon-ico-rss" className="text-12"/>
            <a href="https://twitter.com/canal32?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor" target="_blank" rel="noopener" aria-label="Voir sur Twitter"> 
                <Icon icon="icon-ico-twitter" className="text-14"/>
            </a>
            <a href="https://fr-fr.facebook.com/canal32" target="_blank" rel="noopener" aria-label="Voir sur Facebook">
                <Icon icon="icon-ico-facebook" className="text-14" />
            </a> 
        </div>
    )
}