let defaultWidth = 1248;

const createListOfData = (start, end, gap, typedata, prefixe, suffixe) => {
  let res = {};
  for (let i = start; i <= end; i+=gap) {
    res[`${prefixe}${i}${suffixe}`] = `${i}${typedata}`
  }

  return res;
}

let percentages = createListOfData(1, 100, 1, '%', '', 'per');
let inset_px = createListOfData(4, 300, 4, 'px', '', '');

let width_img = `${16 * 7}px`;
let height_img = `${9 * 7}px`;

let width_px = createListOfData(0, 1000, 1, 'px', '', '');

const minHeight = createListOfData(1, 1000, 1, 'px', '', '');

const dimensionPer = createListOfData(0, 100, 1, '%', '', 'per');
// Define colors

const primaryColors = {
  "primary-blue" : "#2699FB",
  "primary-dark": "#01182C",
  "primary-white": "#FFFFFF",
  "primary-orange": "#FF9D00",
  "primary-grey": "#9AB1C4",
  "primary-red": "#F15152",
}

const lightColors = {
  "light-blue" : "#BCE0FD",
  "light-gray": "#F2F5F7",
}

const allColors = {
  ...primaryColors,
  ...lightColors
}
module.exports = {
  purge: ['./pages/**/*.{js,ts,jsx,tsx}', './components/**/*.{js,ts,jsx,tsx}'],
  theme: {
    extend: {
      backgroundColor: {
        "gray-2" : "#CCD8E2",
        "dark-blue" : "#023B6D",
        "blue-ephemeral-channel" : "#0477DC",
        'black:': "#01182C",
        "primary-blue":"#2699FB",
        ...allColors
      },
      colors : {
        "light-gray": "#F2F5F7",
        "gray" : "#CCD8E2",
        "aboutUs-image" : "#D9D9D9",
        "gray-button-navbar": "#999999",
        "selected-button-navbar": "#4D4D4D",
        "primary-blue" : "#2699FB",
        "primary-dark": "#01182C",
        "primary-white": "#FFFFFF",
        "primary-orange": "#FF9D00",
        "primary-grey": "#9AB1C4",
        "primary-red": "#F15152",
        "dark-blue" : "#023B6D",
        "dark-blue-opacity" : "#023B6D00",
        "dark-blue-opacity-start" : "#023B6DDD",
        "blue-title-sandn": "#023B6D",
        "blue-ephemeral-channel" : "#0477DC",
        'black:': "#01182C",
      },
      lineHeight: createListOfData(4, 100, 2, 'px', '', ''),
      width: {
          'default': `${defaultWidth}px`,
          '1/3' : `${defaultWidth * (1/3)}px`,
          '2/3' : `${defaultWidth * (2/3)}px`,
          'img-16-9':  width_img,
          ...percentages,
          ...width_px,
          
      },
      height: {
        ...percentages,
        ...width_px,
        'img-16-9':  height_img,
        'footer': '50vh',

      },
      screens: {
        'phoneAndTablet' : {'min': '320px', 'max': '1024px'},
        'phone': {'min': '320px', 'max': '639px'},
        'tablet' : {'min': '640px', 'max': '1024px'},
        'sm': {'min': '640px', 'max': '767px'},
        'md': {'max': '1023px'},
        'lg': {'min': '1024px', 'max': '1247px'},
        'default': {'min': `${defaultWidth}px`},
      },
      padding: createListOfData(4, 300, 2, 'px', '', ''),
      margin:  createListOfData(4, 120, 2, 'px', '', ''),
      fontSize: createListOfData(4, 96, 2, 'px', '', ''),
      fontFamily: {
        'nsjp' : ['Noto Sans JP'],
        'lora-regular': ['lora-regular'],
      },
      inset : {
        ...percentages,
        ...inset_px,
        ...createListOfData(-300, 300, 1, 'px', '', ''),
      },
      borderColor : {
        "gray" : "#CCD8E2",
        "blue-ephemeral-channel" : "#0477DC",
        "primary-blue" : "#2699FB",
        "dark-blue" : "#023B6D",
        ...lightColors,
        ...primaryColors
      },
      borderWidth: createListOfData(1, 100, 1, 'px', '', ''),
      backgroundOpacity: {
        '0' : '0.0',
        '10' : '0.1',
        '20' : '0.2',
        '30' : '0.3',
        '40' : '0.4',
        '50' : '0.5',
        '60' : '0.6',
        '70' : '0.7',
        '80' : '0.8',
        '90' : '0.9',
        '100': '1'
      },
      zIndex: {
        '0': 0,
       '10': 10,
       '20': 20,
       '30': 30,
       '40': 40,
       '50': 50,
       '25': 25,
       '50': 50,
       '75': 75,
       '100': 100,
       '999':999,
       '1000':1000,
       '1001':1001,
        'auto': 'auto',
      },
      placeholderColor: {
        "primary-grey": "#9AB1C4",
      },
      minHeight: {
        ...minHeight,
        ...dimensionPer
      },
      maxHeight: {
        ...minHeight,
        ...dimensionPer,
        '1/2' :'50%',
        'full':'100%'

      },
      maxWidth: {
        ...minHeight,
        ...dimensionPer,
        '1/2' :'50%',
        'full':'100%'

      },
      minWidth: {
        ...minHeight,
        ...dimensionPer
      },
      gradientColorStops: {
        "dark-blue" : "#023B6D",
      },
      boxShadow: {
        innerDropListRubrique: 'inset 4px 4px 4px 4px rgba(0, 0, 0, 0.10)',
        none: 'none',
        'primarybutton' : '0px 0px 42px -8px rgba(38,153,251,1)',
        'primarybutton-red':'0px 0px 42px -8px rgba(241, 81, 82,1)'
      },
      animation: {
        wiggle: 'wiggle 1s ease-in-out infinite',
       }
    },
  },
  variants: {
    width: ["responsive", "hover", "focus"],
    position: ['responsive', 'hover', 'focus'],
    padding: ['responsive', 'hover', 'focus'],
    margin: ['responsive', 'hover', 'focus'],
    fontSize: ['responsive', 'hover', 'focus'],
    backgroundColor: ['responsive', 'hover', 'focus', 'active'],
    lineHeight: ['responsive', 'hover', 'focus'],
    inset: ['responsive', 'hover', 'focus'],
    borderColor: ['responsive', 'hover', 'focus', 'active', 'group-hover'],
    borderWidth: ['responsive', 'hover', 'focus'],
    backgroundOpacity: ['responsive', 'hover', 'focus', 'active', 'group-hover'],
    display: ['responsive', 'group-hover', 'group-focus'],
    placeholderColor: ['responsive', 'focus', 'hover', 'active'],
    transitionProperty: ['responsive', 'hover', 'focus'],
    minHeight: ['responsive', 'hover', 'focus'],
    minWidth: ['responsive', 'hover', 'focus'],
    maxHeight: ['responsive', 'hover', 'focus'],
    maxWidth: ['responsive', 'hover', 'focus'],
    gradientColorStops: ['responsive', 'hover', 'focus', 'active', 'group-hover'],
    boxShadow: ['responsive', 'hover', 'focus', 'active', 'group-hover'],
    animation: ['hover', 'focus', 'responsive', 'motion-safe', 'motion-reduce'],
  },
  plugins: [],
}