import { useContext, useEffect, useState } from "react";

import {Context} from "../../utils/store";
import Link from "next/link";
import SocialMedia from "../SocialMedia";
import { useRouter } from "next/router";
import fetch from '../../utils/fetch'
import { useForm } from "react-hook-form";

import ConfirmationDialog from '../../components/Dialog/ConfirmationDialog'

export default function Footer() {
  const router = useRouter();
  const context = useContext(Context);
  const {thematiques} = context;
  const [hasError,setHasError] = useState(false);
  const [openDialog,setOpenDialog] = useState(false);
  const { register: registerNewsletter, handleSubmit: handleSubmitNewsletter, errors: errorsNewsletter } = useForm();
  
  let patternEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const handleCloseDialog= () =>{
    setOpenDialog(false);
  }

  const submitNewsletter = async(data) =>{
    
    await fetch(
      process.env.SOURCE_API + "rest/2.0/newsletter/pushNewsletter.php",
      "POST",
      [
        { name: "email", value: data.email },

    ],
    ).then(result =>{
        if(result.success){
            setHasError(false);
        } else {
            setHasError(true)
        }
    }).catch(error =>{
        setHasError(true)
        
    });
    setOpenDialog(true);
    
  }


  return (
    <>
      <div className="footer-container">
        <div className="footer-container-subcontainer">
          <div className="footer-left-part">
            <div className="newsletter-title">ABONNEZ VOUS À LA NEWSLETTER CANAL 32</div>
            <form className="footer-form"onSubmit={handleSubmitNewsletter(submitNewsletter)}>
              <label htmlFor="email" className="mb-10 block">Toute l'info de Troyes et Aube par mail.</label>
              <input
                placeholder="Entrez votre adresse e-mail"
                className="footer-left-part-input placeholder-primary-grey"
                name="email"
                ref={registerNewsletter({ required: true, pattern: patternEmail })}

              />
              <button type="submit">
                <p className="px-24 py-12 bg-blue-ephemeral-channel rounded-sm">
                  Je m'inscris
                </p>
              </button>
            </form>
            {errorsNewsletter?.email ?
              <div>
                {errorsNewsletter.email.type == 'required' ? 
                 'Ce champs est requis'
                : errorsNewsletter.email.type == 'pattern' ?
                  'Merci de renseigner une adresse mail valide'
                : 'Vérfiez votre saisie'}
              </div>
            :null}
          </div>
          <div className="footer-right-part">
            <div className="footer-right-part-container">
              <div className="footer-vertical-list footer-c32-list">
                <div className="font-bold mb-2">Canal 32</div>
                <Link
                  passHref={true}
                  href={{
                    pathname: "/a-propos"
                  }}
                >
                <a>A propos de nous</a>
                </Link>
                <Link
                  passHref={true}
                  href={{
                    pathname: "/recevoir-la-chaine"
                  }}
                >
                <a>Recevoir la chaine</a>
                </Link>
                <Link
                  passHref={true}
                  href={{
                    pathname: "/contact"
                  }}  
                >
                <a>Nous contacter</a>
                </Link>
                <Link
                  passHref={true}
                  href={{
                    pathname: "/sitemap",
                  }}
                >
                <a>Plan du site</a>
                </Link>
                <Link
                  passHref={true}
                  href={{
                    pathname: "/mentions-legales"
                  }}
                >
                <a>Mention légales</a>
                </Link>
                <Link
                  passHref={true}
                  href={{
                    pathname: "/newsletter"
                  }}
                >
                  <a>Newsletter</a>
                </Link>
                <SocialMedia classNameContainer="footer-social-media space-x-3" />
              </div>
              <div className="footer-vertical-list footer-rubriques-list">
                <div className="font-bold mb-2">Rubriques</div>
                {
                thematiques.items.map((e, i) => {
                  return (
                    <Link
                      key={i}
                      as={`/${e.uri}`}
                      href={{
                        pathname: `/thematiques/[uri]`,
                      }}
                    >
                      <a className="footer-rubrique">
                        {e.pagetitle}
                      </a>
                    </Link>
                  );
                })
                }
              </div>
            </div>
            <div className="footer-annoncer-part">
              <div className="font-bold mb-2 text-xs">Annonceur</div>
              <button>
                <a href="https://lesoffrescanal32.fr/" target="_blank" rel="noopener">Devenir annonceur</a>
              </button>
            </div>
          </div>
        </div>
      </div>    
      <ConfirmationDialog type="newsletter" open={openDialog} hasError={hasError} onClose={handleCloseDialog}/>
      
    </>

  );
}
