import { useContext, useEffect, useState } from "react";

import CloseIcon from '@material-ui/icons/Close';
import { Context } from "../../../utils/store";
import Icon from "../../Icon";
import IconDirect from "../../../public/images/ico/ico-direct-small.svg";
import Link from "next/link";
import LiveLogo from "../../../public/images/ico/ico-direct.svg";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import { useRouter } from "next/router";

function NavBar(props) {
    const router = useRouter();
    const context = useContext(Context);
    const { thematiques, cities } = context;
    const [openMenuVille,setOpenMenuVille] = useState(false);
    const [isClosing,setIsClosing] = useState(false);
    const handleMenuVille = (action) =>{
        if(!openMenuVille && action !='close'){
            document.body.style.overflow='hidden';
            setOpenMenuVille(true);
        }
        else{
            setIsClosing(true);
            setTimeout(function(){
                setOpenMenuVille(false);
                setIsClosing(false);
                document.body.style.overflow='auto';


            },500)
        }

    }

    const Separator = ({className}) => {
        return (
            <div 
                className={`nb-separator ${className}`}
            />
        )
    }
    return (
        <>
            <div className={`overlay ${props.openMenu ? 'open' : ''}`} onClick={()=>{props.handleOpen();handleMenuVille('close')}}/>
            <nav className={`nb-container ${props.openMenu==true ? 'open' : ''}`}>
                <div className="nb-sub-container">
                    <CloseIcon className="block sm:hidden absolute text-dark-blue cursor-pointer close-menu" onClick={()=>props.handleOpen()}/>

                    <div className="nb-rubriques-container">
                        <Link href="/" passHref={true}>
                            <div className={`${router.pathname == "/" ? "nb-menu-item-active nb-menu-item" : "nb-menu-item text-dark-blue"} nb-menu-home cursor-pointer`}>
                            <Icon icon="icon-ico-home" className={`icon-navbar-home ${router.pathname == "/" ? 'text-blue-ephemeral-channel' : 'text-dark-blue'}`}/>
                            <a
                                href={{
                                    pathname: `/`,
                                }}
                            >
                                A la une
                            </a>
                            </div>
                        </Link>
                        <Separator className="mr-20"/>
                        <div className="nb-rubrique-topics-container">
                        {
                            thematiques.items.map((e, i) => {
                                return (
                                    <Link
                                        key={i}
                                        href={{
                                            pathname: `/thematiques/[uri]`,
                                        }}
                                        as={{ pathname: `/${e.uri}` }}
                                    >
                                        <a 
                                            className={
                                                router.asPath == `/thematiques/${e.alias}`
                                                    ? "nb-menu-item-active nb-menu-item"
                                                    : "nb-menu-item"
                                            }
                                            onClick={()=>props.handleOpen}
                                        >
                                            {e.pagetitle}
                                        </a>
                                    </Link>
                                );
                            })
                        }
                    </div>
                    <Separator className="mx-20"/>
                    </div>
                    <div className="nb-right-part">
                        <p className="self-center text-primary-grey hidden sm:inline">|</p>
                        <div className="toggleable" >
                   
                            <div
                                className={`nb-text-info-ville app-button-dropdown${openMenuVille ? '--open' : ''}`}
                                onClick={()=>handleMenuVille()}
                            >
                                Infos de votre ville
                            </div>

               
                        </div>
                        <Separator/>
                        <p className="self-center text-primary-grey hidden sm:inline">|</p>
                        <Link
                            href="/emissions"
                        >
                            <a 
                                className={
                                    router.asPath == `/emissions`
                                        ? "nb-menu-item-active nb-menu-item"
                                        : "nb-menu-item text-dark-blue"
                                }
                                onClick={()=>props.handleOpen}
                            >
                                Émissions
                            </a>
                        </Link>
                        <Link href="/direct">
                            <div className="nb-live-button menu-item see-direct">
                                <LiveLogo className={`nb-live-icon mr-8 ${router.asPath == `/direct` ? 'nb-live-icon-animation': ''}`} />
                                <a 
                                    className={`${router.asPath == `/direct` ? 'nb-live-icon-animation': ''}`}
                                    onClick={()=>props.handleOpen}
                                >
                                    Direct
                                </a>
                            </div>
                        </Link>
                    </div>
                </div>
                
            </nav> 
            <div className={`relative mv-container ${openMenuVille ? `open ${isClosing ? 'is-closing' : ''}`:''}`}>
                <div className="menu-villes" id="menuVille">
                    <div className="relative w-full h-full flex justify-center">
                        <div className="menu-villes__overlay" onClick={()=>{handleMenuVille();props.handleOpen()}}></div>
                        <div className="menu-villes__content shadow-xl bg-light-gray fixed z-20">
                            <div className="px-4 w-full">
                                <h3 className="font-nsjp font-bold text-16 text-primary-dark mb-24 mt-32">
                                    Infos de votre ville
                                </h3>
                                <div className="flex flex-row flex-wrap">
                                    {cities.items.map((e, i) => {
                                        return (
                                            <Link
                                                key={i}
                                                href={{
                                                    pathname: `/ville/[idVille]/[city]`,
                                                }}
                                                as={{
                                                    pathname: `/ville/${e.id}/${e.pagetitle}`,
                                                }}
                                            >
                                                
                                                <a className="
                                                nb-city-item
                                                " onClick={()=>{handleMenuVille();props.handleOpen()}}>
                                                    <p className="font-nsjp font-normal text-16 text-primary-dark">
                                                        {e.pagetitle}
                                                    </p>
                                                    <PlayArrowIcon
                                                        fontSize="small"
                                                        className="text-gray"
                                                    />
                                                </a>
                                            </Link>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>                    
                    </div>

                </div>                 
            </div>
      
        </>

    );
}

export default NavBar;
