import 'pure-react-carousel/dist/react-carousel.es.css';

import { ButtonBack, ButtonNext, CarouselProvider, Slide, Slider } from 'pure-react-carousel';
import { useContext, useState } from "react";

import { Context } from "../../../utils/store";

export default function BreakingNews() {
  let context= useContext(Context);

  let data = context.breakingNews?.items
  return (
    data?.length > 0 ?
        <div className="brn-container relative">
          <CarouselProvider
            naturalSlideWidth={100}
            naturalSlideHeight={100}
            totalSlides={data.length}
            dragEnabled={false}
            isPlaying={true}
            infinite={true}
            touchEnabled={false}
            aria-label="Slider des dernières infos"
          >
            <Slider>
              {data.map((info,index)=>
                <Slide key={index} index={index}>
                  <div className={`brn-info text-center text-primary-white w-full text-xs font-bold ${info.urgent ? 'bg-primary-orange' : 'bg-primary-blue'}`}>
                    {info.titre}                    
                  </div>
                </Slide> 
              )}
              
            </Slider>
          </CarouselProvider>      

      </div>
    :null

  )
}

