import Icon from "../../Icon"
import Link from "next/link";
import MenuIcon from '@material-ui/icons/Menu';
import React from "react";
import RoomIcon from "@material-ui/icons/Room";
import SearchIcon from "@material-ui/icons/Search";
import SendIcon from "@material-ui/icons/Send";
import { useRouter } from "next/router";
import IcomoonReact from "icomoon-react";
import iconSet from '../../../public/selection.json'
const WAIT_INTERVAL = 1000;
const ENTER_KEY = 13;

export default function MainBanner(props) {
    const [timer, setTimer] = React.useState(null);
    const router = useRouter();
    const SearchBar = ({ className }) => {
        const [value, setValue] = React.useState("");

        const handleChange = (event) => {
            let { value } = event.target;
    
            value.length === 1
                ? setValue(value.toUpperCase())
                : setValue(value);
        };

        return (
            <div className={className}>
                <IcomoonReact iconSet={iconSet} icon="ico-headings" size={20} className="mb-search-icon" color="#9AB1C4"/>

                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        router.push("/recherche/[keyWord]", `/recherche/${value}`, {
                            shallow: false,
                        });
                    }}
                >
                    <input
                        type="text"
                        placeholder="Recherche"
                        value={value}
                        onChange={handleChange}
                    />
                </form>
               
            </div>
        )
    }

    return (
        <div className="mb-container">
            <div className="mb-sub-container">
                <div className="mb-left-part">
                    <Link
                        href='/'
                    >
                        <img
                            alt="Logo de l'entreprise Canal 32"
                            src="/images/logo-canal32.jpeg"
                            className="cursor-pointer"
                        />                        
                    </Link>
                    <Link
                        href='/'
                    >
                        <div className="mb-left-text-container cursor-pointer">
                            <div className="logo-title">{"L'ACTUALITÉ DANS L'AUBE 24H/24"}</div>
                            <h3>{"TV TROYES ET AUBE"}</h3>
                        </div>
                    </Link>
                    <div className="mb-responsive-menu--toggle">
                        <button onClick={()=>props.handleOpen()}>
                            <MenuIcon/>                            
                        </button>

                    </div>
                </div>
                <div className="mb-right-part">
                    <SearchBar className="mb-search-bar-header" key="searchbar1" />
                    <div className="mb-btn-container">
                        <Link
                            href={{
                                pathname: "/autour-de-moi",
                            }}
                        >
                            <div className="mb-btn-right-part mb-btn-right-part-aroundme">
                                <Icon icon="icon-ico-around" className="mb-btn-right-icon"/>
                                <p>Autour de moi</p>
                            </div>
                        </Link>
                        <Link
                            href={{
                                pathname: "/contact",
                            }}
                        >
                            <div className="mb-btn-right-part">
                            <Icon icon="icon-ico-contact" className="mb-btn-right-icon"/>
                                <p>Contact</p>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}
