var FormData = require('form-data');
import { v4 as uuidv4 } from 'uuid';

const CryptoJS = require("crypto-js");

const _encode = async () => {
    let uuid    = uuidv4();
    let ref     = uuid.split('-')[2];
    let encoded = await CryptoJS.MD5('sinfinxc32'); 
    let key     = `${Math.random().toString(36).substr(2, 3)}${encoded}${Math.random().toString(36).substr(2, 3)}`;
    uuid = uuid.replace(ref, key);
    return uuid;
};

const loadDataFromApi = async(uri, method, body, setHeader=false,header={}) => {
    let formData;
    if(method=='GET'){
        formData=null;
    } else{
        formData= new FormData();
        let token = await _encode();
        formData.append('token',token);
        if (body != null) {
            body.map((e, i) => {
                formData.append(e.name, e.value);
            });
        } 
    }    



    if (setHeader) {
      header = header
    }
    return new Promise((resolve, reject) => {
      try {
          fetch(
          uri,
          {
            method: method,       
            body: formData,
          }
          
        ).then(async (value) => {
          console.log('aaa',value,uri,method)
          if(value){
            const response = await value.json();
            resolve(response);            
          } else {
            resolve(null)
          }

        }).catch(error =>{
          console.error(error);
          reject(error);
        });
      } catch (error) {
        console.error(error);
        reject(error);
      }
    });
  };

  export default loadDataFromApi;