import { Context } from "../../utils/store";
import { FlashAutoOutlined } from "@material-ui/icons";
import Link from "next/link";
import { useContext, useState, useEffect } from "react";
import { useRouter } from "next/router";

const Element = (props) => {
  return (
    <Link
      key={props.index}
      as={{ pathname: props.link }}
      href={{ pathname: props.pathname ? props.pathname : props.link }}
    >

      <a className={`bc__item ${props.isCurrent ? 'active' : ''}`}>{props.title}{props.isCurrent}</a>
    </Link>

  )
}

const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

export default function Breadcrumb(props) {
  const router = useRouter();
  const context = useContext(Context);
  const { thematiques, rubriqueData } = context;
  const [path, setPath] = useState({});
  const initPath = () => {
    let path = [{
      link: '/',
      title: 'A la une'
    }]

    let rubrique, article, link;

    switch (props.type) {

      case 'home':
        break;
      case 'article':
        let current = props.data[0];

        rubrique = {
          title: current.parentName,
          link: `/${current.parentUri}`,
          pathname: "/thematiques/[uri]"
        }

        article = {
          title: current.pagetitle,
          link: `/${current.uri}`,
          pathname: "/thematiques/[...uri]"
        }

        path.push(rubrique, article)

        break;

      case 'rubrique':
        rubrique = {
          title: rubriqueData.parentName,
          link: ''
        }
        path.push(rubrique)
        break;

      case 'shows':
        if (router.pathname.includes('/[idEmission]')) {
          let show = context.currentShow;
          rubrique = {
            title: 'Emissions',
            link: '/emissions'
          }
          article = {
            title: show.pagetitle,
            link: `/emissions/${show.id}`,
            pathname: '/emissions/[idEmission]'
          }
          path.push(rubrique, article)
        } else {
          rubrique = {
            title: 'Emissions',
            link: '/emissions'
          }
          path.push(rubrique)
        }

        break;

      case 'cities':
        rubrique = {
          title: 'Villes',
          link: '/autour-de-moi'
        }
        article = {
          title: context.cityName,
          link: `/ville/${context.cityId}/${context.cityName}`,
          pathname: '/ville/[idVille]/[city]'
        }
        path.push(rubrique, article);
        break;

      case 'aroundme':
        link = {
          title: 'Autour de moi',
          link: '/autour-de-moi',
        }
        path.push(link);
        break;

      case 'imprint':
        link = {
          title: 'Mentions légales',
          link: '/mentions-legales',
        }
        path.push(link);
        break;

      case 'sitemap':
        link = {
          title: 'Plan du site',
          link: '/sitemap',
        }
        path.push(link);
        break;

      case 'aboutUs':
        link = {
          title: 'A propos',
          link: '/a-propos',
        }
        path.push(link);
        break;

      case 'gettingUs':
        link = {
          title: 'Nous recevoir',
          link: '/recevoir-la-chaine',
        }
        path.push(link);
        break;

      case 'searched':
        rubrique = {
          title: 'Recherche',
          link: '/'
        }
        article = {
          title: context.keyWord,
          link: `/recherche/${context.keyWord}`,
          pathname: '/recherche/[keyWord]'
        }
        path.push(rubrique, article);
        break;
      
      case 'chaine-ephemere':
          rubrique = {
            title : 'Chaines éphémères',
            link : '/'
          }
          article={
            title : context.ecPageName,
            pathname:router.route,
            link:router.asPath,
          }
          path.push(rubrique, article);
          break;

      default:
        let linkName = router.pathname.replace('/', '');
        linkName = capitalize(linkName);
        link = {
          title: linkName,
          link: '',
        }
        path.push(link);
        break;


    }
    return (path)
  }

  useEffect(() => {
    let path = initPath();
    setPath(path);
  }, [props.type, props.data])

  return (
    <div className="bc__container">
      {path?.length > 0 ?
        path.map((el, i) => (
          <Element link={el.link} title={el.title} index={i} isCurrent={i == (path.length - 1) ? true : false} />
        ))
        : null}
    </div>
  )
}
